<script>
import scrollIntoView from "scroll-into-view-if-needed"
import { A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/swiper-vue"
import CurrentBasket from "@/components/mobilepos/button-view/CurrentBasket.vue"
import ItemSelectionMixin from "@/mixins/item-selection.mixin"
import Items from "./Items.vue"

const scrollToSelectedGroup = (groupRef, index) => {
  const el = groupRef?.children?.[index] ?? null
  if (!el) return

  scrollIntoView(el, {
    behavior: "smooth",
    scrollMode: "if-needed",
    block: "nearest",
    inline: "nearest",
  })
}

export default {
  name: "ItemSelection",

  props: ["area_id", "table_id"],
  emits: ["openLineNote"],
  inject: ["swiperSlide", "openBasket", "openCurrentBasket", "addQuantity"],

  mixins: [ItemSelectionMixin],
  components: {
    CurrentBasket,
    Items,
    SwiperSlide,
    Swiper,
  },

  data: () => ({
    swiper: null,
    modules: [A11y],
  }),

  provide() {
    return {
      itemAdd: this.itemAdd,
      itemClear: this.itemClear,
    }
  },

  mounted() {
    void this.getItems()

    this.$watch(
      () => this.selectedGroupId,
      () => {
        const index = !this.selectedGroupId
          ? this.groups.length
          : this.groups.findIndex((g) => String(g.id) === String(this.selectedGroupId))

        // Scroll to the selected group
        scrollToSelectedGroup(this.$refs.groupRef, index)
      }
    )
  },

  methods: {
    selectGroup(group) {
      this.selectedGroupId = group?.id ?? null

      const index = !this.selectedGroupId
        ? this.groups.length
        : this.groups.findIndex((g) => String(g.id) === String(this.selectedGroupId))

      this.swiper?.slideTo?.(index, 350)
    },

    onSlideChange(swiper) {
      const i = swiper.activeIndex
      const isLastSlide = i === this.groups.length

      this.selectedGroupId = isLastSlide ? null : this.groups[i].id
    },
  },

  computed: {
    groupRows() {
      if (this.groups.length <= 6) return [this.groups]

      const mid = Math.ceil(this.groups.length / 2)
      return [this.groups.slice(0, mid), this.groups.slice(mid)]
    },
  },
}
</script>

<template>
  <div class="px-3">
    <CurrentBasket
      :last-added-item-id="lastAddedItemId"
      @item-add="(line) => addQuantity(line)"
      @open-basket="openBasket"
      @open-current-basket="openCurrentBasket"
      @open-line-note="$emit('openLineNote', $event)"
    />
  </div>

  <nav class="nav-categories px-3 py-3 no-swiping">
    <div
      ref="groupRef"
      v-if="groups.length > 0"
      class="w-full overflow-x-scroll overflow-y-hidden space-y-1"
    >
      <ul
        v-for="(groupRow, index) in groupRows"
        :key="index"
        class="flex flex-no-wrap justify-start items-center scrollbar-hide font-bold text-lg space-x-4"
      >
        <li
          v-for="(group, index) in groupRow"
          :key="index"
          class="flex-shrink-0 truncate category-item pb-1"
          :class="{
            'text-sjefgreen-500 active rounded-sm': selectedGroupId == group.id,
          }"
          @click="selectGroup(group)"
        >
          {{ group.name }}
        </li>

        <li
          v-if="index === groupRows.length - 1"
          class="flex-shrink-0 truncate category-item pb-1"
          :class="{ 'text-sjefgreen-500 active rounded-sm': !selectedGroupId }"
          @click="selectGroup(null)"
        >
          {{ $t("translations.components.modal-item-select.all-items") }}
        </li>
      </ul>
    </div>
  </nav>

  <div class="flex-1 flex-shrink-0 h-full overflow-hidden">
    <swiper
      v-if="groups.length > 0"
      ref="swiper"
      :nested="true"
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      @swiper="(instance) => (swiper = instance)"
      @slide-change="onSlideChange"
      class="h-full text-left"
    >
      <swiper-slide
        v-for="(group, index) in groups"
        :key="index"
        class="h-full px-3"
      >
        <div class="h-full overflow-y-auto">
          <Items
            :group="group"
            :items="indexedItems?.[group.id] ?? []"
          />
        </div>
      </swiper-slide>

      <swiper-slide class="h-full px-3">
        <div class="pb-3">
          <input
            type="search"
            v-model="searchquery"
            placeholder="Zoeken"
            class="rounded bg-gray-100 py-1 px-2 block w-full search focus:outline-none"
          />
        </div>

        <div class="h-full overflow-y-auto">
          <Items
            :items="allItems"
            :search="searchquery"
          />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
